<template>
<div id="age-consent-modal" class="modal modal-layout-custom">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modalheader">
                <!-- <span class="close-button">&times;</span> -->
            </div>
            <div class="modal-body">
                <div class="form-group text-center">
                    <div class="row text-center content-center">
                        <h2>{{ $t('AgeRestrictionTittle') }}</h2>
                    </div>
                    <div class="row">
                        <h5>{{ $t('AgeRestrictionDiscreption') }}</h5>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <button class="btn mt-2 text-uppercase white cursor" @click="cancleModal()">{{ $t("CANCEL") }}</button>
                        </div>
                        <div class="col-6">
                            <button class="button-primary  mt-2 text-uppercase cursor" @click="acceptRestriction()" :style="currentLanguage == 'mr' ? 'padding: 0.1rem 0.75rem' : '' ">{{ $t('IAMOVER18') }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer"></div>
        </div>
    </div>
</div>
</template>
<script>
// script here
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
export default {
    events: ['onActionComplete'],
    props: ['payload'],
    data() {
        return {
            currentLanguage: null
        }
    },
    mounted() {
        this.currentLanguage = this.getCurrentLanguageUrlBase();
    },
    created() {

    },
    methods: { 
        cancleModal() {
            // eventBus.$emit('open-age-restriction-modal', false);
            this.$emit("onActionComplete", false);
        },
        acceptRestriction() {
            this.$emit("onActionComplete", true);
            // eventBus.$emit('acceptAutoPlay', true);
        }
    },
    mixins: [Utility],
}
</script>
<style lang="scss" scoped>

.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999!important;
    .modal-dialog {
        position: relative;
        min-width: 50%;
        width: auto;
        margin: 0.5rem;
        pointer-events: none;
        .modal-content {
            position: relative;
            display: flex;
            flex-direction: column;
            pointer-events: auto;
            border: 1px solid rgba(0,0,0,.2);
            background-clip: padding-box;
            outline: 0;
            .modal-body {
                max-height: calc(100vh - 100px);
                overflow-y: auto;
                padding: 1rem;
                position: relative;
                flex: 1 1 auto;
            }
        }
    }

}
.modal-layout-custom {
    display: block;
    background: rgba(0,0,0,.8);
    border-radius: 0;
    color: #fff;
    opacity: 1;
    overflow-y: auto;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
    .modal-dialog-centered {
        display: flex;
        align-items: center;
        min-height: calc(100% - 1rem);
        justify-content: center;
        .modal-content {
            width: 340px;
            background-color: #1e1e1e;
            background: #2d2d2d;
            border-radius: 0;
            margin: auto;
            .modal-body {
                max-height: calc(120vh - 100px);
                overflow-y: inherit;
                padding: 25px 40px;
                .form-group {
                    .row {
                        display: flex;
                        flex-wrap: wrap;
                        margin-right: -15px;
                        margin-left: -15px;
                        .col-6 {
                            flex: 0 0 50%;
                            max-width: 50%;
                            position: relative;
                            width: 100%;
                            padding-right: 15px;
                            padding-left: 15px;
                            .btn {
                                font-size: 13px;
                                height: 39px;
                                width: 114px;
                            }
                            .white {
                                color: #fff;
                                border: 1px solid #fff;
                            }
                            .red-btn {
                                opacity: 1;
                                background-color: #ff0032;
                            }
                        }
                    }:not(:last-child) {
                        margin-bottom: .8rem;
                    }
                }
            }
        }
    }
}


.close-button {
    float: right;
    width: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    cursor: pointer;
    border-radius: 0.25rem;
    background-color: lightgray;
}

.close-button:hover {
    background-color: darkgray;
}
.text-center {
    text-align: center!important;
}
.text-uppercase {
    text-transform: uppercase!important;
}
.mt-2 {
    margin-top: 0.5rem!important;
}
.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.content-center {
    justify-content: center;
    align-items: center;
}
.cursor {
    cursor: pointer;
}
</style>